import { graphql, useStaticQuery } from "gatsby";

interface RlhsiteNode {
  field_filter_selected: string;
}

interface RlhsiteEdge {
  node: RlhsiteNode;
}

interface RlhsiteData {
  allRlhsite: {
    edges: RlhsiteEdge[];
  };
}

export const useRlhsiteData = (): RlhsiteData => {
  const data: RlhsiteData = useStaticQuery(graphql`
    query {
      allRlhsite(filter: { machine_name: { eq: "sonesta" } }) {
        edges {
          node {
            field_filter_selected
          }
        }
      }
    }
  `);

  return data;
};
