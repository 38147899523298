import Axios from "axios";
import { graphql } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Constants } from "../../@types/Constants";
import IParagraph from "../../@types/IParagraph";
import DeepLinking from "../../components/common/DeepLinking/DeepLinking";
import FeaturesTabsCity from "../../components/common/FeaturesTabsCity/FeaturesTabsCity";
import ParagraphLayout from "../../components/common/ParagraphLayout/ParagraphLayout";
import Layout from "../../components/global/Layout/Layout";
import SEO from "../../components/global/SEO/SEO";
import Title2 from "../../components/global/Title2/Title2";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { searchSelector, setSearch } from "../../redux/slices/Search/search";
import { addPageTypeInGTMDataLayer } from "../../utils/datalayers";
import { getParagraph } from "../../utils/paragraphHelpers";
import {
  HotelListing,
  PopularAttractions,
  SubTitle2,
} from "./PoiLocationHelpers";
import { IPoiLocationProps } from "./PoiLocationProps";
//import { addKoddiPageTag } from "../../utils/KoddiTagsGTM";
import BreadCrumb from "../../components/common/Breadcrumb/Breadcrumb";
import useBrandBias from "../../hooks/useBrandBias";
import { useWebFramed } from "../../hooks/useWebFramed";
import { guestServiceProvider } from "../../services/ServiceProvider";
import { extractBrandFromUrl } from "../../utils/helpers";
import { useLocationData } from "../../hooks/useLocationData";
import { useRlhsiteData } from "../../hooks/useRlhsiteData";

const PoiLocation: React.FC<IPoiLocationProps> = (props) => {
  const dispatch = useAppDispatch();
  const isWebFramed = useWebFramed();
  const search = useAppSelector(searchSelector);
  const {
    poiLocationName,
    seoTitle,
    seoDesc,
    noIndex,
    noFollow,
    coordinates,
    countryCode,
    attractionTabHeader,
    sectionHeader,
    statePath,
    cityPath,
  } = props.pageContext;

  const poiLocation = props.data.locationPointOfInterest;
  const hotelReferences =
    poiLocation &&
    poiLocation?.hotel_reference &&
    poiLocation?.hotel_reference.length
      ? poiLocation?.hotel_reference.filter((hotel) => hotel?.status === "1")
      : [];

  const SITE_ID = 31;

  const [points, setPoints] = useState([]);
  const [distance, setDistance] = useState(10);

  const [isLoading, setIsLoading] = useState(false);

  const location = props.location;
  const brandsArr = extractBrandFromUrl(location.search);
  const poiLocationBrandCode =
    location.state && location.state.brand && location.state.brand.brand_code;

  const { allRlhsite } = useRlhsiteData();
  const { locationStates, locationCitys } = useLocationData();

  const filteredLocationState = useMemo(() => {
    if (!statePath) {
      return null;
    }
    return locationStates.nodes.find((node) => node.path.alias === statePath);
  }, [locationStates.nodes, statePath]);

  const filteredLocationCity = useMemo(() => {
    if (!cityPath) {
      return null;
    }
    return locationCitys.nodes.find((node) => node.path.alias === cityPath);
  }, [locationCitys.nodes, cityPath]);

  const hotelSearchFilters = allRlhsite.edges.length
    ? allRlhsite.edges[0].node.field_filter_selected
    : [];

  useBrandBias();
  useEffect(() => {
    if (coordinates !== null) {
      const payload = {
        latitude: coordinates?.lat,
        longitude: coordinates?.lon,
        searchString: poiLocationName,
        searchType: "point_of_interest",
        distance: 50,
        country: countryCode,
        customUrl: location?.pathname || search?.customUrl || "",
        // rooms: [new Room()],
        // checkin: search.checkin,
        // checkout: search.checkout,
        // discount: search.discount,
        // promotionCode: search.promotionCode,
        // groupCode:  search.groupCode
      };

      dispatch(setSearch(payload));
    }
    addPageTypeInGTMDataLayer("search");
  }, []);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    let didCancel = false;
    (async () => {
      if (!didCancel && coordinates?.lat && coordinates?.lon && distance) {
        setIsLoading(true);
        let url = `/location-proximity?coordinates[value]=${distance}`;
        url += `&coordinates[source_configuration][origin][lat]=${coordinates?.lat}&coordinates[source_configuration][origin][lon]=${coordinates?.lon}`;
        url += "&type[]=point_of_interest";

        // const data = await coreGet(url, source);

        //Use Location Proximity Response
        const guestService = await guestServiceProvider();
        const response = await guestService.getLocationProximity(
          distance,
          coordinates?.lat,
          coordinates?.lon
        );
        if (response?.searchLocations?.results) {
          const filteredPoints = response.searchLocations.results.filter(
            (location) => location.locationType === "point_of_interest"
          );
          setPoints(filteredPoints);
          setIsLoading(false);
        }
      }
    })();
    return () => {
      didCancel = true;
      source.cancel("Request cancelled for points of interest.");
    };
  }, [distance]);

  const filterSonestaParagraphs = (paragraph: {
    relationships: { field_sites: { machine_name: string } };
  }) => {
    return paragraph?.relationships?.field_sites
      ? paragraph?.relationships.field_sites.machine_name === "sonesta"
        ? true
        : false
      : false;
  };

  const handleSetDistance = (newDistance: number) => {
    setDistance(newDistance);
  };

  const siteParagraphs = poiLocation?.relationships.paragraphs?.filter(
    filterSonestaParagraphs
  );

  const paragraphs = siteParagraphs
    ? siteParagraphs.map((paragraph) => {
        if (paragraph.type === "paragraph__photo_gallery") {
          return getParagraph(paragraph, {
            imgCountPerRow: 1,
          });
        } else {
          return getParagraph(paragraph, {});
        }
      })
    : [];

  const belowSearchSiteParagraphs =
    poiLocation?.relationships.paragraphsBelowSearch?.filter(
      filterSonestaParagraphs
    );

  const belowSearchParagraphs = belowSearchSiteParagraphs
    ? belowSearchSiteParagraphs.map((paragraph) => {
        if (paragraph.type === "paragraph__photo_gallery") {
          return getParagraph(paragraph, {
            imgCountPerRow: 1,
          });
        } else {
          return getParagraph(paragraph, {});
        }
      })
    : [];

  const pageBanner = paragraphs.filter((p: IParagraph) => {
    return p
      ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER
      : false;
  });
  const pageIntro = paragraphs.filter((p: IParagraph) => {
    return p ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_INTRO : false;
  });

  //features
  const sonestaFeatures = poiLocation.relationships.field_features.filter(
    (feature) =>
      feature.relationships?.field_sites
        ? feature.relationships.field_sites.machine_name === "sonesta"
          ? true
          : false
        : true
  );
  return (
    <Layout showMerchandisingBlock={countryCode == "US"}>
      <SEO
        title={seoTitle || poiLocationName}
        noIndex={noIndex}
        noFollow={noFollow}
        description={seoDesc || ""}
        includeLeaflet
        koddiTitle="POI-Location"
      />
      {pageBanner}
      <ParagraphLayout {...paragraphs} />
      <HotelListing
        poiLocation={poiLocation}
        sortBy={poiLocationBrandCode ? "brand" : "distance"}
        location={props.location}
        hotelSearchFilters={hotelSearchFilters}
        isBannerAndIntroAcailable={
          pageIntro.length > 0 && pageBanner.length > 0 ? true : false
        }
        brandsInUrl={brandsArr}
        hotelReferences={hotelReferences}
      />
      {/* Search result block will come here*/}
      <DeepLinking location={location} />
      <ParagraphLayout
        {...belowSearchParagraphs}
        isbelowSearchParagraphs={true}
      />
      {sonestaFeatures !== null && sonestaFeatures.length ? (
        <Container
          className="bg-light pt-4 pb-4 pt-lg-5 pb-lg-5 mb-4 mb-lg-5 position-relative"
          fluid
        >
          <a
            className="anchor-target visually-hidden"
            id="HotelDetails"
            aria-hidden={true}
          />
          <Container fluid="sm" className="p-0">
            <Row>
              <SubTitle2>{sectionHeader || "About The Attraction"}</SubTitle2>
              <Title2>{attractionTabHeader || "Attractions"}</Title2>
            </Row>

            <FeaturesTabsCity features={sonestaFeatures} />
          </Container>
        </Container>
      ) : null}

      <PopularAttractions
        points={points}
        isLoading={isLoading}
        poiLocation={poiLocation}
        coordinates={coordinates}
        distance={distance}
        setDistance={handleSetDistance}
      />
      {!isWebFramed && (
        <div className={"bg-light pt-4 pb-4 pt-lg-5 pb-lg-5 position-relative"}>
          <div className={"container-sm"}>
            <BreadCrumb
              location={location}
              activeTitle={poiLocationName}
              countryCode={countryCode}
              cityPath={filteredLocationCity}
              statePath={filteredLocationState}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    locationPointOfInterest(fields: { path: { alias: { eq: $slug } } }) {
      name
      hotel_reference {
        name
        status
        brand {
          code
          logoUrl
          name
        }
        coordinates {
          latitude
          longitude
        }
        address {
          locality
          streetAddress1
          postalCode
          cityName
          country
          region
        }
        crsHotelCode
        crsName
        gallery
        galleryImages {
          alt
          url
        }
        hotelCode
        hotelId
        phone
        rangeInMilesFromOrigin
        url
        amenities
        amenityLabels
        tagline
        tags
      }
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphPageBanner
          ...ParagraphPageIntro
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphPhotoGallery
          ...ParagraphFrequentlyAskedQuestions
        }
        paragraphsBelowSearch: field_sections_below_search {
          type: __typename
          ...ParagraphPageBanner
          ...ParagraphPageIntro
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphPhotoGallery
          ...ParagraphFrequentlyAskedQuestions
        }
        field_features {
          field_feature_tab_title
          field_feature_title
          field_feature_description {
            value
          }
          field_feature_image {
            alt
          }
          relationships {
            field_sites {
              machine_name
            }
            field_feature_image {
              url
            }
            field_sub_feature {
              field_feature_description {
                value
              }
              field_feature_tab_title
              field_feature_title
              field_feature_image {
                alt
              }
              relationships {
                field_feature_image {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PoiLocation;
