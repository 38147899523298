import { useEffect } from "react";
import { Storage } from "../utils/storage";
import { extractBiasFromUrl } from "../utils/helpers";
import { Constants } from "../@types/Constants";

const useBrandBias = (): void => {
  useEffect(() => {
    const brandBiasfromUrl = extractBiasFromUrl(location.search);
    const brandBias = Storage.GetLocalStorageValue(Constants.BRAND_BIAS_KEY);
    if (brandBiasfromUrl && brandBiasfromUrl !== brandBias?.brand_code) {
      const brandInfo = {
        brand_code: brandBiasfromUrl,
      };
      const brandInfoString = JSON.stringify(brandInfo);
      Storage.SetLocalStorageValue(Constants.BRAND_BIAS_KEY, brandInfoString);
    }
  }, []);
};

export default useBrandBias;
