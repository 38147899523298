import { graphql, useStaticQuery } from "gatsby";

interface Path {
  alias: string;
}

interface StateNode {
  path: Path;
}

interface CityNode {
  path: Path;
}

interface LocationData {
  locationStates: {
    nodes: StateNode[];
  };
  locationCitys: {
    nodes: CityNode[];
  };
}

export const useLocationData = (): LocationData => {
  const data: LocationData = useStaticQuery(graphql`
    query {
      locationStates: allNodeState {
        nodes {
          path {
            alias
          }
        }
      }
      locationCitys: allLocationCity {
        nodes {
          path {
            alias
          }
        }
      }
    }
  `);

  return data;
};
