// extracted by mini-css-extract-plugin
export var alt = "PoiLocation-module--alt--64e23";
export var container = "PoiLocation-module--container--e342b";
export var desktopOnly = "PoiLocation-module--desktopOnly--8787d";
export var main = "PoiLocation-module--main--8abe6";
export var mobileOnly = "PoiLocation-module--mobileOnly--aa4a7";
export var subTitle2 = "PoiLocation-module--subTitle2--39e07";
export var textLeft = "PoiLocation-module--textLeft--4b476";
export var title = "PoiLocation-module--title--94bdb";
export var title2 = "PoiLocation-module--title2--ba83c";
export var visuallyHidden = "PoiLocation-module--visuallyHidden--71096";